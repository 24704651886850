"use strict";

/**
 * Created by Thiago Lima on 20/02/2020.
 */

(function () {
  'use strict';

  function dependencies(window) {
    return {
      VueI18n: window.VueI18n,
      pt: window.pt,
      en: window.en
    };
  }
  var _dependencies = dependencies(window),
    VueI18n = _dependencies.VueI18n,
    pt = _dependencies.pt,
    en = _dependencies.en;
  var messages = {
    pt: pt,
    en: en
  };
  var i18n = new VueI18n({
    locale: 'pt',
    // set locale
    messages: messages // set locale messages
  });

  window.moduleExport({
    i18n: i18n
  }, typeof module !== 'undefined' && module);
})();